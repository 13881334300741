body {
	background-color: black !important;
}
#app {
	position: absolute;
	height: 100%;
	width: 100%;
	color: white;
}
#header {
	
}
#wrapper {
	margin: 10px;
}

#content {
	min-height: 568px;
	background-color: #64646482;
	border: solid 1px gray;
}

#footer {
	
}

h2, h4 {
	font-family: Trebuchet MS;
	text-align: center;
}

td, tr {
	text-align: center;
}
hr {
	background: white;
	margin-top: 1rem;
	margin-bottom: 1rem;
	border: 0;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
}

